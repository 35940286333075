import React from 'react';
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import Layout from '../components/layout';
import SEO from '../components/seo';



const Contact = ( { data } ) => {
    return (  
        <Layout>
            <SEO 
            pageType="website"
            linkTitle={data.contact.contactPageLinkTitle}
            pageDescription={data.contact.contactPageLinkDescription.contactPageLinkDescription}
            pageURL={`${data.site.siteMetadata.url}contact`}
            pageTitle={data.contact.contactPageTitle}
            linkImage={`https:${data.contact.contactPageImage.file.url}`}
            />
            <TopBanner>
                <ContactHeadline>{data.contact.contactPageHeadline}</ContactHeadline>
            </TopBanner>
            <div style={{display: "flex", flexDirection: "column"}}>
            <LowerSection>
                <ContactWrapper>
                    <ContactDetails>
                        <ContactMessage>{data.contact.contactMessage.contactMessage}</ContactMessage>
                        <ContactEmail href={`mailto:${data.contact.contactEmailAddress}`} >{data.contact.contactEmailAddress}</ContactEmail>
                    </ContactDetails>
                    <ContactProfile fluid={data.contact.contactPageProfilePhoto.fluid}/>

                </ContactWrapper>
                

            </LowerSection>
            </div>
           
        </Layout>
    );
}
 
export default Contact;

export const pageQuery = graphql`
    query{
    contact: contentfulSiteSettings(title: {eq: "Site Settings"}) {
        contactPageTitle
        contactPageLinkTitle
        contactPageImage {
            file {
                url
            }
        }
        contactPageLinkDescription {
            contactPageLinkDescription
        }
        contactPageHeadline
        contactMessage {
            contactMessage
        }
        contactEmailAddress
        contactPageProfilePhoto {
                fluid(maxWidth: 2000) {
                    ...GatsbyContentfulFluid
                }
            }
        }
        site {
            siteMetadata {
                url
            }
        }
    }
`

const TopBanner = styled.div`
    margin-top: 68px;
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #0a3b7c;
    display: flex;
    justify-content: center;
    align-items: center !important;
`

const ContactHeadline = styled.h1`
    color: #FFFFFF;
    text-align: center;
    font-size: 38px;
    font-weight: 900;
    font-family: 'Montserrat', sans-serif !important;
    background-color: #00AEEF;
    padding: 10px;
    display: inline;
    @media (max-width: 450px) {
    font-size: 9vw;
    }
`

const LowerSection = styled.div`
    background-color: #FFFFFF;
    padding-top: 10vh;
    padding-bottom: 20vh;
    padding-left: 30px;
    padding-right: 30px;
    overflow: auto;
    @media (max-width: 768px) {
        padding-bottom: 29vh;
    }

    @media (max-width: 768px) {
        padding-bottom: 20vh;
    }
`

const ContactWrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1.3fr 2fr !important;
    grid-template-rows: auto;
    border-radius: 2px !important;
    overflow: hidden;

    @media (max-width: 450px) {
        grid-template-columns: 1fr !important;
        grid-template-areas:
        "photo"
        "message";
    }

    

`
const ContactDetails = styled.div`
    background-color: #00aeef;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;

    @media (max-width: 450px) {
        text-align: center;
        font-size: 3vw;

        grid-area: message;
    }

`

const ContactMessage = styled.p`
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;

`

const ContactEmail = styled.a`
    text-decoration: none;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    &:hover{
        color: #0a3b7c;
        transition: all 300ms linear 0s ;
    }
`

const ContactProfile = styled(Img)`
    width: 100%;
    @media (max-width: 450px) {
        grid-area: photo;
    }
    
`